import React from 'react';
import SingleColPageLayout from '../templates/single-col-page-layout';
import MetaTags from '../components/MetaTags';

const TITLE = 'Case Studies';

const studies = [
  {
    title: '3Advance — Serverless Transformation',
    permalink: '/case-studies/3advance-serverless-transformation/',
    logo: '/img/client-logos/3advance.png',
    description:
      'I guided a team of developers in an app development firm through a transformation from a serverful .NET stack to AWS serverless.\n',
  },
  {
    title: 'BBC Discover',
    permalink: '/case-studies/bbc-discover/',
    logo: '/img/client-logos/bbc.svg',
    description:
      'Following on from the successful roll-out of the Archive Search project, I was re-engaged to consult on the delivery of a complementary web app to exhibit archive content to the general public.\n',
  },
  {
    title: 'BBC Archive Search (Rewind)',
    permalink: '/case-studies/bbc-rewind/',
    logo: '/img/client-logos/bbc.svg',
    description:
      'I led the design and implementation of a search and discovery portal for the BBC Rewind project which enables journalists and researchers to search across millions of the corporation’s digitised archive assets within seconds, pulling in metadata from disparate sources to enhance result relevance.\n',
  },
  {
    title: 'Bluesky Interactive',
    permalink: '/case-studies/bluesky/',
    logo: '/img/client-logos/bluesky.png',
    description:
      'I worked with the Bluesky team to integrate Autochart lead profiling into their automotive dealer website platform. This enabled their clients to learn more about what their online visitors are searching for and ultimately convert more leads to sales.\n',
  },
  {
    title: 'autoTRADER.ca',
    permalink: '/case-studies/autotrader/',
    logo: '/img/client-logos/autotrader.png',
    description:
      'I rebuilt the front end of the mobile website for autoTRADER.ca — the top automotive classifieds website in Canada — providing mobile users with a rich vehicle search experience on their device.',
  },
];

const Page = ({ path }) => {
  const items = studies.map((study) => {
    const titleMarkup = study.permalink ? (
      <a href={study.permalink}>{study.title}</a>
    ) : (
      study.title
    );
    return (
      <li className="border-b-2 border-gray-200" key={study.permalink}>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 pt-2 pr-8 md:pb-20 pl-4 text-center mx-auto">
            <img
              src={study.logo}
              alt={study.title}
              className="w-48 inline-block"
            />
          </div>
          <div className="w-full md:w-2/3">
            <h3 className="case-study-title">{titleMarkup}</h3>
            <p>{study.description}</p>
            {study.permalink && (
              <p className="text-right">
                <a href={study.permalink}>Read more...</a>
              </p>
            )}
          </div>
        </div>
      </li>
    );
  });
  return (
    <SingleColPageLayout>
      <MetaTags title={TITLE} path={path} />
      <h1 className="page-heading">{TITLE}</h1>
      <p>
        I have worked with several great clients over the past few years. Here
        are a few examples of how I have helped them improve their businesses
        with my solutions.
      </p>
      <ul className="list-none m-0 p-0">{items}</ul>
    </SingleColPageLayout>
  );
};

export default Page;
